<script>
import simplebar from 'simplebar-vue'
import { layoutComputed } from '@/state/helpers'

import MetisMenu from 'metismenujs/dist/metismenujs'

import { menuItems } from './menu'

export default {
  components: {
    simplebar,
  },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menuItems: menuItems,
    }
  },
  computed: {
    ...layoutComputed,
  },
  watch: {
    $route: {
      handler: 'onRoutechange',
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
          case 'dark':
            document.body.setAttribute('data-sidebar', 'dark')
            document.body.removeAttribute('data-topbar')
            document.body.removeAttribute('data-sidebar-size')
            break
          case 'light':
            document.body.setAttribute('data-topbar', 'dark')
            document.body.removeAttribute('data-sidebar')
            document.body.removeAttribute('data-sidebar-size')
            document.body.classList.remove('vertical-collpsed')
            break
          case 'compact':
            document.body.setAttribute('data-sidebar-size', 'small')
            document.body.setAttribute('data-sidebar', 'dark')
            document.body.classList.remove('vertical-collpsed')
            document.body.removeAttribute('data-topbar', 'dark')
            break
          case 'icon':
            document.body.setAttribute('data-keep-enlarged', 'true')
            document.body.classList.add('vertical-collpsed')
            document.body.setAttribute('data-sidebar', 'dark')
            document.body.removeAttribute('data-topbar', 'dark')
            break
          case 'colored':
            document.body.setAttribute('data-sidebar', 'colored')
            document.body.removeAttribute('data-keep-enlarged')
            document.body.classList.remove('vertical-collpsed')
            document.body.removeAttribute('data-sidebar-size')
            break
          default:
            document.body.setAttribute('data-sidebar', 'dark')
            break
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
          case 'boxed':
            document.body.setAttribute('data-layout-size', 'boxed')
            break
          case 'fluid':
            document.body.setAttribute('data-layout-mode', 'fluid')
            document.body.removeAttribute('data-layout-size')
            break
          default:
            document.body.setAttribute('data-layout-mode', 'fluid')
            break
          }
        }
      },
    },
  },
  mounted: function() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu('#side-menu')
    var links = document.getElementsByClassName('side-nav-link-ref')
    var matchingMenuItem = null
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      var parent = matchingMenuItem.parentElement

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('mm-active')
        const parent2 = parent.parentElement.closest('ul')
        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show')

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.add('mm-active')
            var childAnchor = parent3.querySelector('.has-arrow')
            var childDropdown = parent3.querySelector('.has-dropdown')
            if (childAnchor) childAnchor.classList.add('mm-active')
            if (childDropdown) childDropdown.classList.add('mm-active')

            const parent4 = parent3.parentElement
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show')
              const parent5 = parent4.parentElement
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active')
                const childanchor = parent5.querySelector('.is-parent')
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active')
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false
    },
    onRoutechange() {
      setTimeout(() => {
        const currentPosition = document.getElementsByClassName('mm-active')[0]
          .offsetTop
        if (currentPosition > 400)
          this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 200
      }, 300)
    },
  },
}
</script>
<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar id="my-element" ref="currentMenu" class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul id="side-menu" class="metismenu list-unstyled">
          <!-- Left Menu Start 
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-user-settings-line"></i>
              <span>Contact</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li>
                <a @click="$router.push('Contact');" href="javascript: void(0);">Contact Data</a>
              </li>
              <li>
                <a @click="$router.push('User');" href="javascript: void(0);">User Setting</a>
              </li>
              <li>
                <a @click="$router.push('Priority_Pattern');" href="javascript: void(0);">Priority Pattern</a>
              </li>
            </ul>
          <li>
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-settings-2-line"></i>
              <span>Setting</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li>
                <a @click="$router.push('Company_Setting');" href="javascript: void(0);">Company Setting</a>
              </li>
              <li>
                <a @click="$router.push('Office');" href="javascript: void(0);">Office</a>
              </li>
              <li>
                <a @click="$router.push('Subdivision');" href="javascript: void(0);">Subdivision</a>
              </li>
              <li>
                <a @click="$router.push('Job');" href="javascript: void(0);">Job</a>
              </li>
            </ul>
          </li>
          -->

          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-user-settings-line" />
              <span>Blank Page</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li>
                <a href="javascript: void(0);" @click="$router.push('/Normal');">Normal</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('/WSearch');">With Search</a>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);" class="side-nav-link-ref" @click="$router.push('/Contact');">
              <i class="ri-user-settings-line" />
              <span>หน้าควบคุม</span>
            </a>
          </li>

          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-user-settings-line" />
              <span>ใบสั่งซื้อ</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li>
                <a href="javascript: void(0);" @click="$router.push('Order_retail');">Retail</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('Order_sub_to_depot');">Sub to Depot</a>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-user-settings-line" />
              <span>วางแผนขนส่ง</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li>
                <a href="javascript: void(0);" @click="$router.push('Contact');">Retail</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User');">Sub to Depot</a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="javascript: void(0);"
              class="side-nav-link-ref"
              @click="$router.push('Contact')"
            >
              <i class="ri-user-settings-line" />
              <span>ติดตามการขนส่ง</span>
            </a>
          </li>
          <li>
            <a
              href="javascript: void(0);"
              class="side-nav-link-ref"
              @click="$router.push('Contact')"
            >
              <i class="ri-user-settings-line" />
              <span>แจ้งเตือน</span>
            </a>
          </li>
          <li>
            <a
              href="javascript: void(0);"
              class="side-nav-link-ref"
              @click="$router.push('Contact')"
            >
              <i class="ri-user-settings-line" />
              <span>จัดการค่าใช้จ่าย</span>
            </a>
          </li>
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-user-settings-line" />
              <span>จัดการข้อมูล</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li>
                <a href="javascript: void(0);" @click="$router.push('/Station');">ข้อมูลสถานี</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">ข้อมูลประเภทยานพาหนะ</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User');">ข้อมูลยานพาหนะ</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">ข้อมูลคลังน้ำมัน</a>
              </li>
        
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">ข้อมูลโซนปฏิบัติงาน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('/Contractor')">ข้อมูล ผรม.</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('/Contact')">ข้อมูล ลูกค้า</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">ประเภทค่าใช้จ่าย</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-user-settings-line" />
              <span>รายงาน</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li class="menu-title"> รายงานการขนส่ง </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานแผนการจัดส่ง</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปเวลาปฏิบัติงาน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปเวลาการเดินทางเทียบกับแผนงาน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปการขนส่ง</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปปริมาณการจัดส่งน้ำมัน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานรถ พร้อม/ไม่พร้อม ใช้งาน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปความพึงพอใจ</a>
              </li>
              <li class="menu-title"> รายงานค่าใช้จ่าย </li>
             
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานข้อมูลน้ำมันประจำสถานี</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('Station')">รายงานค่าขนส่ง</a> 
              </li><li /><li>
                <a href="javascript: void(0);" @click="$router.push('Station')">รายงานค่าขนส่ง(ผรม)</a> 
              </li><li>
                <a href="javascript: void(0);" @click="$router.push('Station')">รายงานค่าใช้จ่ายในการขนส่ง</a>
              </li>
              <li class="menu-title"> รายงานการปฏิบัติงาน </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปเวลาการปฏบัติงานของพนักงาน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานสรุปผลงาน พขร.</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานรายได้ของพนักงาน</a>
              </li>
              <li>
                <a href="javascript: void(0);" @click="$router.push('User')">รายงานประวัติการรับรางวัล</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-settings-2-line" />
              <span>Theme Menu</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <template v-for="item in menuItems">
                <li v-if="item.isTitle" :key="item.id" class="menu-title">
                  {{ $t(item.label) }}
                </li>

                <!--end Layouts menu -->
                <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
                  <a
                    v-if="hasItems(item)"
                    href="javascript:void(0);"
                    class="is-parent"
                    :class="{
                      'has-arrow': !item.badge,
                      'has-dropdown': item.badge,
                    }"
                  >
                    <i v-if="item.icon" :class="`bx ${item.icon}`" />
                    <span>{{ $t(item.label) }}</span>
                    <span
                      v-if="item.badge"
                      :class="
                        `badge badge-pill badge-${item.badge.variant} float-right`
                      "
                    >{{ $t(item.badge.text) }}</span>
                  </a>

                  <router-link
                    v-if="!hasItems(item)"
                    :to="item.link"
                    class="side-nav-link-ref"
                  >
                    <i v-if="item.icon" :class="`bx ${item.icon}`" />
                    <span>{{ $t(item.label) }}</span>
                    <span
                      v-if="item.badge"
                      :class="
                        `badge badge-pill badge-${item.badge.variant} float-right`
                      "
                    >{{ $t(item.badge.text) }}</span>
                  </router-link>

                  <ul
                    v-if="hasItems(item)"
                    class="sub-menu"
                    aria-expanded="false"
                  >
                    <li v-for="(subitem, index) of item.subItems" :key="index">
                      <router-link
                        v-if="!hasItems(subitem)"
                        :to="subitem.link"
                        class="side-nav-link-ref"
                      >
                        {{ $t(subitem.label) }}
                      </router-link>
                      <a
                        v-if="hasItems(subitem)"
                        class="side-nav-link-a-ref has-arrow"
                        href="javascript:void(0);"
                      >{{ subitem.label }}</a>
                      <ul
                        v-if="hasItems(subitem)"
                        class="sub-menu mm-collapse"
                        aria-expanded="false"
                      >
                        <li
                          v-for="(subSubitem, index) of subitem.subItems"
                          :key="index"
                        >
                          <router-link
                            :to="subSubitem.link"
                            class="side-nav-link-ref"
                          >
                            {{ $t(subSubitem.label) }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </template>
              <li>
                <a href="javascript: void(0);" class="has-arrow waves-effect">
                  <i class="ri-settings-2-line" />
                  <span>Melti Level</span>
                </a>
                <ul class="sub-menu" aria-expanded="true">
                  <li>
                    <a href="javascript: void(0);" @click="$router.push('Job');">Level 1.1</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="has-arrow">Level 1.2</a>
                    <ul class="sub-menu" aria-expanded="true">
                      <li>
                        <a href="javascript: void(0);">Level 2.1</a>
                      </li>
                      <li>
                        <a href="javascript: void(0);">Level 2.2</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>
